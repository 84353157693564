<template>
  <div class="signup bg-orbs-light">
    <div v-if="!isLoading" class="signup__container">
      <div class="signup__intro">
        <div class="signup__intro-card">
          <h2 class="signup__intro-title">
            {{ wizard ? $t('signup.intro.title') : $t('merchant.createFull') }}
          </h2>
          <p class="signup__intro-text">
            {{ wizard ? $t('signup.intro.text') : $t('merchant.createLeadIn') }}
          </p>
          <Cards class="signup__intro-cards" />
        </div>
      </div>

      <form class="signup__form" @submit.prevent="handleSubmit">
        <div class="signup__form-container">
          <template v-if="wizard">
            <h3 class="signup__form-title">
              {{ $t('signup.form.purposeTitle') }}
            </h3>
            <p>{{ $t('signup.form.experience') }}</p>
            <ChecRadioGroup
              v-model="purpose"
              name="signup_purpose"
              class="signup__purpose"
              :options="purposes"
            />
            <ChecDropdown
              v-model="role"
              :label="$t('signup.form.roleTitle')"
              :options="roles"
              name="user_role"
              required
            />
          </template>

          <h4 class="signup__form-subtitle">
            {{ $t('signup.form.merchantDetails') }}
          </h4>
          <div class="signup__merchant-details">
            <TextField
              v-model="merchant.name"
              name="merchant_name"
              :label="$t('signup.form.merchantName')"
              class="signup__merchant-field"
              required
            />
            <p>{{ $t('signup.form.merchantNameDescription') }}</p>

            <TextField
              v-model="merchant.email"
              type="email"
              name="merchant_email"
              :label="$t('signup.form.merchantEmail')"
              class="signup__merchant-field"
              required
            />
            <p>{{ $t('signup.form.merchantEmailDescription') }}</p>

            <ChecDropdown
              v-model="merchant.currency"
              :options="currencies"
              name="merchant_currency"
              :label="$t('general.currency')"
              class="signup__merchant-field"
              required
              show-search
              :search-value="searchTerm"
              @search="handleSearch"
            />
          </div>
        </div>

        <div class="signup__form-actions">
          <ChecButton
            v-if="!wizard"
            color="primary"
            tag-type="route"
            text-only
            :disabled="saving"
            :to="{ name: 'home' }"
          >
            {{ $t('general.cancel') }}
          </ChecButton>
          <ChecButton
            v-else
            color="primary"
            text-only
            type="button"
            :disabled="saving"
            @click="logout"
          >
            {{ $t('account.logout') }}
          </ChecButton>
          <ChecButton
            color="primary"
            icon="right-arrow"
            icon-position="after"
            button-type="submit"
            :disabled="saving"
          >
            {{ saving ? $t('signup.form.saving') : $t('signup.form.next') }}
          </ChecButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecDropdown,
  ChecRadioGroup,
  TextField,
} from '@chec/ui-library';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { makeApiRequest } from '@/lib/api';
import supportedCurrencies from '@/lib/currencies';
import addNotification from '@/mixins/addNotification';
import mutations from '@/store/mutations';
import { mutations as paginationMutations } from '@/lib/pagination';
import authState from '@/lib/auth/state';
import Cards from '../assets/cards.svg';

export default {
  name: 'Signup',
  components: {
    Cards,
    ChecButton,
    ChecDropdown,
    ChecRadioGroup,
    TextField,
  },
  mixins: [addNotification],
  data() {
    return {
      merchant: {
        name: '',
        email: '',
        currency: 'USD',
      },
      purpose: '',
      role: '',
      saving: false,
      searchTerm: '',
      wizard: this.$route?.name === 'signup',
    };
  },
  computed: {
    ...mapState('merchant', { userMerchant: 'merchant' }),
    ...mapGetters(['isLoading', 'userData']),
    /**
     * List of signup purposes
     */
    purposes() {
      return [
        {
          value: 'migrating',
          label: this.$t('signup.purposes.migrating'),
        },
        {
          value: 'launching',
          label: this.$t('signup.purposes.launching'),
        },
        {
          value: 'researching',
          label: this.$t('signup.purposes.researching'),
        },
        {
          value: 'student',
          label: this.$t('signup.purposes.student'),
        },
        {
          value: 'other',
          label: this.$t('signup.purposes.other'),
        },
      ];
    },
    /**
     * List of user roles
     */
    roles() {
      return [
        {
          value: 'developer',
          label: this.$t('signup.roles.developer'),
        },
        {
          value: 'cto',
          label: this.$t('signup.roles.cto'),
        },
        {
          value: 'solution_architect',
          label: this.$t('signup.roles.solution_architect'),
        },
        {
          value: 'owner',
          label: this.$t('signup.roles.owner'),
        },
        {
          value: 'manager',
          label: this.$t('signup.roles.manager'),
        },
        {
          value: 'marketing',
          label: this.$t('signup.roles.marketing'),
        },
        {
          value: 'student',
          label: this.$t('signup.roles.student'),
        },
        {
          value: 'other',
          label: this.$t('signup.roles.other'),
        },
      ];
    },
    /**
     * Returns a map of supported currencies in a format that our dropdowns can consume
     *
     * @returns {object[]}
     */
    currencies() {
      return supportedCurrencies
        .filter((currency) => {
          if (!this.searchTerm) {
            return currency;
          }
          // If searching, perform a lowercase search match
          const term = this.searchTerm.toString().toLowerCase();
          return currency.code.toLowerCase().includes(term)
            || currency.name.toLowerCase().includes(term);
        })
        .map((currency) => ({
          value: currency.code,
          label: currency.name,
        }));
    },
  },
  mounted() {
    if (this.userData?.email) {
      // Set the email to default to the email the user signed up with
      this.merchant.email = this.userData.email;
    }
  },
  methods: {
    ...mapMutations('merchant', {
      setMerchant: mutations.SET_MERCHANT,
      addNewMerchant: paginationMutations.ADD_ITEM,
    }),
    /**
     * Apply search from currency dropdown
     *
     * @param {string} value
     */
    handleSearch(value) {
      this.searchTerm = value.trim();
    },
    /**
     * Handles the form submission by sending an API call to create the merchant
     */
    async handleSubmit() {
      try {
        this.saving = true;
        const { data: merchant } = await makeApiRequest('POST', '/v1/merchants', {
          sellers_name: this.merchant.name,
          sellers_email: this.merchant.email,
          currency: this.merchant.currency,
          signup_purpose: this.purpose || undefined,
          signup_role: this.role || undefined,
        });

        // Set the merchant in state using the response provided by the API
        this.setMerchant({ merchant });
        this.addNewMerchant({
          context: {},
          item: merchant,
          prepend: true,
        });

        this.$router.push({ name: 'home' });
      } catch (error) {
        this.addNotification(this.$t('signup.error'), 'error');
        this.saving = false;
      }
    },
    logout() {
      authState.logout();
    },
  },
};
</script>

<style lang="scss">
.signup {
  @apply
    h-screen w-full text-gray-600 bg-cover bg-no-repeat bg-white
    flex justify-center;

  @screen md {
    @apply items-center;
  }

  &__container {
    @apply flex flex-col;

    @screen lg {
      height: 900px;
      width: 1440px; // todo should this be something in the UI library?
      @apply flex-row;
    }
  }

  &__intro,
  &__form,
  &__form-actions {
    // Page margin, essentially
    @apply p-8;
  }

  // Intro is typically the left side container
  &__intro {
    @apply w-full;

    @screen lg {
      width: 560px; // todo should this be something in the UI library?
    }
  }

  &__intro-card {
    @apply
      relative h-full rounded-lg shadow-lg bg-no-repeat bg-bottom bg-hologram-light
      p-10 text-center;

    @screen lg {
      @apply p-20 text-left;
    }
  }

  &__intro-title {
    @apply text-4xl font-bold mb-4;
  }

  &__intro-text {
    @apply text-lg text-gray-500;
  }

  &__intro-cards {
    @apply hidden absolute bottom-0;
    left: -29px; // to center the image

    @screen lg {
      @apply block;
    }
  }

  // Form is typically the right side container
  &__form {
    @apply relative flex flex-col flex-shrink-0 items-center justify-center;

    @screen md {
      @apply flex-grow;
    }

    p {
      @apply text-gray-500 text-xs;
    }
  }

  &__form-container {
    @apply max-w-lg;
  }

  &__form-title {
    @apply text-2xl font-bold;
  }

  &__form-subtitle {
    @apply caps-xxs mt-20;
  }

  &__merchant-field {
    @apply mt-4 mb-1;
  }

  &__form-actions {
    @apply flex;

    @screen lg {
      @apply absolute bottom-0 right-0;
    }
  }

  &__purpose {
    @apply my-4;

    .chec-radio {
      @apply text-gray-500;
    }
  }
}
</style>
