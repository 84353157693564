<template>
  <div class="merchant-list">
    <div v-if="merchants.length > 0">
      <ChecButton
        v-for="merchant in merchants"
        :key="merchant.id"
        variant="small"
        class="merchant-list__merchant"
        @click="switchMerchant(merchant.id)"
      >
        <MerchantTag :merchant="merchant" />
      </ChecButton>
      <DashboardPagination state-key="merchant" />
    </div>
    <div v-else class="merchant-list__empty">
      {{ $t('merchant.noMerchants') }}
    </div>
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import { mapPagination } from '@/lib/pagination';
import DashboardPagination from '@/components/Pagination.vue';
import MerchantTag from '@/modules/merchant/components/MerchantTag.vue';
import switchMerchant from '@/modules/merchant/mixins/switchMerchant';

export default {
  name: 'MerchantList',
  components: {
    ChecButton,
    DashboardPagination,
    MerchantTag,
  },
  mixins: [switchMerchant],
  computed: {
    ...mapPagination({ merchants: 'merchant' }),
  },
};
</script>

<style lang="scss">
.merchant-list {
  &__merchant {
    @apply block w-full p-4 text-left;

    & + & {
      @apply border-t border-gray-200;
    }
  }

  &__empty {
    @apply block w-full p-4 pt-8 text-center border-t border-gray-200;
  }
}
</style>
