<template>
  <div>
    <div v-if="!initiallyLoaded" class="loading-container loading-container--full-screen">
      <ChecLoading />
    </div>
    <template v-else>
      <div class="merchant-list-view bg-confetti">
        <div class="merchant-list-view__card">
          <DashboardHeader
            :title="$t('merchant.selectMerchant')"
            class="merchant-list-view__header"
            margin="small"
            variant="card"
          >
            <ChecButton
              color="gray"
              variant="round"
              icon="plus"
              tag-type="route"
              :to="{ name: 'merchant.new' }"
            >
              {{ $t('merchant.createMerchant') }}
            </ChecButton>
          </DashboardHeader>
          <MerchantList class="merchant-list-view__list" />
          <div class="merchant-list-view__profile">
            <CurrentUser invert />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecLoading,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import { actions } from '@/lib/pagination';
import DashboardHeader from '@/components/DashboardHeader.vue';
import CurrentUser from '@/components/Navigation/CurrentUser.vue';
import MerchantList from '../components/MerchantList.vue';

export default {
  name: 'List',
  components: {
    ChecButton,
    ChecLoading,
    CurrentUser,
    DashboardHeader,
    MerchantList,
  },
  data() {
    return {
      initiallyLoaded: this.isLoading,
    };
  },
  computed: {
    ...mapState('merchant', ['isLoading']),
  },
  async mounted() {
    await this.loadPage(1);
    this.initiallyLoaded = true;
  },
  methods: {
    ...mapActions('merchant', { loadPage: actions.SET_PAGE }),
  },
};
</script>

<style lang="scss">
.merchant-list-view {
  @apply min-h-screen w-full bg-cover flex items-center justify-center;

  &__header {
    @apply p-4 pb-0;
  }

  &__list {
    @apply mx-4;
  }

  &__card {
    @apply shadow-md rounded-md bg-white m-4;

    @screen md {
      min-width: 30rem;
    }
  }

  &__profile {
    @apply mt-4;

    .button--variant-small {
      @apply rounded-t-none rounded-b-md;
    }
  }
}
</style>
